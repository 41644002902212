import { useCallback, useState } from 'react'
import { isAxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import {  toast } from 'react-toastify'
import {
  SignUpErrorResponse,
  SignupFormProps,
  SignUpPayloadProps,
  signupStepFormSchema,
} from './types'
import { api } from '../../services/api'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom'
import { ROUTES_PATH } from '../../routes'
import { usePrivacyPolicy } from '../../store/usePrivacyPolicy'
import { SignInDiv } from '../../components/SignInDiv'
import { Form, FormInput, FormSubmit } from '../../components'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

export function SignUp() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isPrivacyPolicyOpen } = usePrivacyPolicy()
  const [payload, setPayload] = useState<SignUpPayloadProps>({
    name: '',
    password: '',
    email: ''
  })

  const signupStepForm = useForm<SignupFormProps>({
    resolver: zodResolver(signupStepFormSchema),
    defaultValues: {
      name: payload?.name || '',
      email: payload?.email || '',
      password: payload?.password || '',
      confirmPassword: payload?.confirmPassword || ''
    }
  })

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting }
  } = signupStepForm


  const handleSignUp = useCallback(
    async (data: SignUpPayloadProps) => {
      console.log(data)
      try {
        const newPayload: SignUpPayloadProps = {
          ...payload,
          ...data,
          hadConsent: !isPrivacyPolicyOpen
        }
        delete newPayload.address?.description
        setPayload(prevState => ({ ...prevState, ...data }))
        const { data: signUnData } = await api.post<any>(
          'accounts/sign-up',
          newPayload
        )

        // console.log(newPayload)

        if (signUnData) {
          console.log(signUnData)
          toast.success("Email to validation was sent.", {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: 'dark'
          })
          navigate(
            `${ROUTES_PATH.HOME.path}`
            )

        }
      } catch (error) {
        if (isAxiosError<SignUpErrorResponse>(error)) {
          const errorMessages = error?.response?.data?.message

          if (errorMessages?.length) {
            errorMessages.forEach(errorMessage => {
              toast.error(errorMessage.Message, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                theme: 'dark'
              })
            })
          } else {
            toast.error(t('sign-up.request-error'), {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              theme: 'dark'
            })
          }
        } else {
          toast.error(t('sign-up.request-error'), {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: 'dark'
          })
        }
      }
    },
    [isPrivacyPolicyOpen, navigate, payload, t]
  )

  return (
    <>
      <SignInDiv id={ROUTES_PATH.SIGN_UP.path}>
        <h1 className="text-2xl flex items-center gap-4">
          {t('sign-up.title')}
        </h1>
        <Form onSubmit={handleSubmit(handleSignUp)}>
      <FormProvider {...signupStepForm}>
        <FormInput
          inputName="name"
          className="h-12 text-base placeholder:text-base"
          placeholder={t('sign-up.form.name')}
          errorMessage={errors.name?.message}
          {...register('name')}
        />
        <FormInput
          inputName="email"
          className="h-12 text-base placeholder:text-base"
          placeholder={t('sign-up.form.email')}
          errorMessage={errors.email?.message}
          {...register('email')}
        />
        <FormInput
          inputName="password"
          className="h-12 text-base placeholder:text-base"
          placeholder={t('sign-up.form.password')}
          type="password"
          errorMessage={errors.password?.message}
          {...register('password')}
        />
        <FormInput
          inputName="confirmPassword"
          className="h-12 text-base placeholder:text-base"
          placeholder={t('sign-up.form.confirm-password')}
          type="password"
          errorMessage={errors.confirmPassword?.message}
          {...register('confirmPassword')}
        />
        <FormSubmit isSubmitting={isSubmitting} className="h-12 text-base">
          <span className="text-base font-medium">
            {t('sign-up.form.submit-btn')}
          </span>
          {/* <ArrowRightIcon /> */}
        </FormSubmit>
      </FormProvider>
    </Form>
      </SignInDiv>
      {/* <ToastContainer /> */}
    </>
  )
}
