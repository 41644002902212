import { useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import { useSearchParams } from 'react-router-dom'
import { NFTCatalogResumeContent } from '../../components'
import { ROUTES_PATH } from '../../routes/utils'
import { useAuth, useCartStore } from '../../store'
import { MapboxMaps } from '../../components/NFTMap'
import { getFeedMapsByUser } from '../../services/hooks/useNFTs'
import { useTranslation } from 'react-i18next'
import { MapNFT } from '../../models/map-nft'
import { NFTAnimateGuideContent } from '../../components/NFTAnimateGuide'
import { NFTOwnedCatalogContent } from '../../components/NFTOwnedCatalog'

export function Catalog() {
  const { token } = useAuth()
  const { t } = useTranslation()
  const { validNFTs } = useCartStore();
  const [searchParams] = useSearchParams()
  
  const [activeTab, setActiveTab] = useState(0);
  const [activeDesktopTap, setActiveDesktopTab] = useState(2);
  const [ownedNFTs, setOwnedNFTs] = useState<(MapNFT)[]>([])
  const [selectedOwnedNFT, setSelectedOwnedNFT] = useState<MapNFT | null>(null)

  const cartValidNfts = validNFTs();

  useEffect(() => {
    if (!token) return

    const fetchData = async () => {
      const currentOwnedNFT = await getFeedMapsByUser()

      if (currentOwnedNFT?.length) {
        setActiveDesktopTab(1)
        setOwnedNFTs(currentOwnedNFT);
      }
    }

    fetchData()
  }, [token])

  const mobileNavTabs = useMemo(() => {
    const tabs = []

    if (ownedNFTs?.length) {
      tabs.push({ key: 1, label: `Meus tokens (${ownedNFTs.length})`})
    } else {
      tabs.push({ key: 2, label: 'Info'})
    }

    if (cartValidNfts?.length) {
      tabs.push({ key: 3, label: `${t('catalog.tab-summary')} (${cartValidNfts.length})` })
    }

    return tabs;
  }, [cartValidNfts.length, t, ownedNFTs?.length])

  const hasPriceParam = useMemo(() => {
    const param = searchParams.get('price');

    return !!param?.length && ['25', '100'].includes(param);
  }, [searchParams])

  useEffect(() => {
    if (hasPriceParam && !!cartValidNfts.length) {
      setActiveTab(3);
      setActiveDesktopTab(3);
    } else if (
      !cartValidNfts.length &&
      !hasPriceParam &&
      activeTab === 3
    ) {
      setActiveTab(ownedNFTs?.length ? 1 : 2)
      setActiveDesktopTab(ownedNFTs?.length ? 1 : 2)
    }
  }, [activeTab, cartValidNfts.length, hasPriceParam, ownedNFTs?.length])

  const handleSelectedOwnedNFT = (nft: MapNFT) => {
    setSelectedOwnedNFT(nft)
    setActiveTab(0)
    setActiveDesktopTab(1)
  }

  return (
    <>
      <nav className={cx(
        'bg-amz3green-300 text-amz3green-50 fixed top-[96px] right-0 w-full lg:w-[600px] z-10'
      )}>
        <ul className='catalog-tabgroup'>
          <li className={cx(
            activeTab === 0 ? 'active' : null,
            'lg:hidden'
          )}>
            <button
              className="catalog-tab"
              onClick={()=> {
                setActiveTab(0)
                setActiveDesktopTab(ownedNFTs?.length ? 1 : 2)
              }}
            >
              <span>{t('catalog.tab-map')}</span>
            </button>
          </li>
          {mobileNavTabs.map(({ key, label }) => (
            <li
              key={key}
              className={cx(
                activeTab === key ? 'active' : null,
                activeDesktopTap === key ? 'active-desktop' : null,
              )}
            >
              <button
                className='catalog-tab'
                onClick={()=> {
                  setActiveTab(key)
                  setActiveDesktopTab(key)
                }}
              >
                <span>{label}</span>
              </button>
            </li>
          ))}
        </ul>
      </nav>
      <section
        className="lg:flex lg:flex-row h-[calc(100dvh-96px)] overflow-y pt-[52px] lg:pt-0"
        id={ROUTES_PATH.CATALOG.id}
      >
        <div className={cx(
          activeTab === 0 ? 'block' : 'hidden',
          "lg:block lg:w-[calc(100%-600px)] h-full"
        )}>
          <MapboxMaps userNFTs={ownedNFTs} selectedOwnedNFT={selectedOwnedNFT} />
        </div>
        <NFTAnimateGuideContent
          className={cx(
            activeTab === 2 ? 'block' : 'hidden',
            activeDesktopTap === 2 ? 'lg:block' : 'lg:hidden',
            "bg-amz3green-300 lg:w-[600px] p-8 lg:pt-20 h-full"
          )}
        />
        <NFTCatalogResumeContent
          className={cx(
            activeTab === 3 ? 'block' : 'hidden',
            activeDesktopTap === 3 ? 'lg:block' : 'lg:hidden',
            "bg-amz3green-300 lg:w-[600px] p-8 h-full lg:pt-20"
          )}
          cartNFTs={cartValidNfts}
        />
        <NFTOwnedCatalogContent
          className={cx(
            activeTab === 1 ? 'block' : 'hidden',
            activeDesktopTap === 1 ? 'lg:block' : 'lg:hidden',
            "bg-amz3green-300 lg:w-[600px] p-8 lg:pt-20 h-full overflow-y-auto"
          )}
          ownedNFTs={ownedNFTs}
          setSelectedOwnedNFT={handleSelectedOwnedNFT}
        />
        {/* <Drawer isOpen={openCart && !!validNFTs().length} ></Drawer> */}
      </section>
      {/* {!!validNFTs().length && <NFTCatalogResume />} */}
    </>
  )
}
