import { create, StateCreator } from 'zustand'
import { persist, PersistOptions } from 'zustand/middleware'
import { MapNFT } from '../../models/map-nft'
import { AREA_OPTIONS } from '../../components/NFTMap/types'
import { v4 } from 'uuid'

export type CurrencyProps = 'USD' | 'SOL' | 'BRL'

type CartState = {
  id: string
  cartNFTs: MapNFT[]
  validNFTs: () => MapNFT[]
  currency: CurrencyProps
  setCurrency: (currencyValue: CurrencyProps) => void
  addNFT: (nft: MapNFT) => void
  deleteNFT: (nft: MapNFT) => void
  selectNFT: (nft: MapNFT) => void
  clearNFTs: () => void
  updateNFTStatus: (
    nftId: string,
    status: keyof typeof AREA_OPTIONS,
    selectedNft?: MapNFT
  ) => void
}

type PersistProps = (
  config: StateCreator<CartState>,
  options: PersistOptions<CartState>
) => StateCreator<CartState>

export const useCartStore = create<CartState>(
  (persist as PersistProps)(
    (set, get) => {
      return {
        id: v4(),
        cartNFTs: [],
        currency: 'USD',
        validNFTs: () =>
          get().cartNFTs.filter(
            nft => nft.status === 'SELECTED' || nft.status === 'ACTIVE'
          ),
        setCurrency: (currencyValue: CurrencyProps) =>
          set(() => ({
            currency: currencyValue
          })),
        addNFT: nft =>
          set(state => {
            return {
              cartNFTs: [...state.cartNFTs, { ...nft, status: 'SELECTED' }]
            }
          }),
        deleteNFT: nft =>
          set(state => {
            return {
              cartNFTs: state.cartNFTs.filter(cartNFT => cartNFT.id !== nft.id)
            }
          }),
        selectNFT: nft =>
          set(state => {
            const existsNFTIndex = state.cartNFTs.findIndex(
              cartNFT => cartNFT.id === nft.id
            )

            if (existsNFTIndex === -1) {
              return {
                cartNFTs: [...state.cartNFTs, { ...nft, status: 'SELECTED' }]
              }
            } else {
              const updatedCartNFTs = [...state.cartNFTs]
              updatedCartNFTs.splice(existsNFTIndex, 1)

              return {
                cartNFTs: updatedCartNFTs
              }
            }
          }),
        clearNFTs: () =>
          set(state => ({
            cartNFTs: [],
            id: v4()
          })),
        updateNFTStatus: (nftId, status, selectedNft) =>
          set(state => {
            const nft = !!selectedNft
              ? selectedNft
              : state.cartNFTs.find(cartNFT => cartNFT.id === nftId)
            if (nft) {
              const existsNFT = state.cartNFTs.find(
                cartNFT => cartNFT.id === nft.id
              )

              if (!existsNFT) {
                return {
                  cartNFTs: [...state.cartNFTs, { ...nft, status }]
                }
              } else {
                const updatedCartNFTs = state.cartNFTs.map(cartNFT => {
                  return cartNFT.id === nft.id
                    ? { ...cartNFT, status }
                    : cartNFT
                })

                return {
                  cartNFTs: updatedCartNFTs
                }
              }
            } else {
              return {
                cartNFTs: state.cartNFTs
              }
            }
          })
      }
    },
    {
      name: 'cart-storage'
    }
  )
)
