import { NOTIFICATION_STATUS } from '../Notification/types'
import { z } from 'zod'
import i18n from '../../i18n'
import { validatePassword } from '../../utils'

export type SignUpPayloadProps = {
  name?: string
  password?: string
  confirmPassword?: string
  cpf?: string
  phoneNumber?: string
  phoneDdi?: string
  address?: SignUpPayloadAddressProps
  email?: string
  hadConsent?: boolean
}

export type SignUpPayloadAddressProps = {
  description?: string
  street?: string
  neighborhood?: string
  number?: string
  city?: string
  state?: string
  countryCode?: string
  zipCode?: string
  complement?: string | null
}

// export interface SignUpSuccessResponse {
//   message: string
//   status: keyof typeof NOTIFICATION_STATUS
//   title: string
// }
export interface SignUpSuccessResponse {
  messages:{
   Item:string 
   Messsage:{
    en:string
    pt:string
   }
  }[]
  status: keyof typeof NOTIFICATION_STATUS
  title: string
}

export interface SignUpErrorResponse {
  title: string
  message: Message[]
  status: string
}

export interface Message {
  Item: string
  Message: string
}



export let signupStepFormSchema: z.ZodEffects<
  z.ZodEffects<
    z.ZodObject<
      {
        name: z.ZodString
        email: z.ZodString
        password: z.ZodString
        confirmPassword: z.ZodString
      },
      'strip',
      z.ZodTypeAny,
      {
        password: string
        email: string
        confirmPassword: string
        name: string
      },
      { password: string; email: string; confirmPassword: string; name: string }
    >,
    { password: string; email: string; confirmPassword: string; name: string },
    { password: string; email: string; confirmPassword: string; name: string }
  >,
  { password: string; email: string; confirmPassword: string; name: string },
  { password: string; email: string; confirmPassword: string; name: string }
>

i18n.on('languageChanged', language => {
  signupStepFormSchema = z
    .object({
      name: z.string().min(2, {
        message: i18n?.t('sign-up.form.error-messages.name', { lng: language })
      }),
      email: z.string().email({
        message: i18n?.t('sign-up.form.error-messages.email', { lng: language })
      }),
      password: z.string().min(2, {
        message: i18n?.t('sign-up.form.error-messages.password', {
          lng: language
        })
      }),
      confirmPassword: z.string().min(2, {
        message: i18n?.t('sign-up.form.error-messages.confirm-password', {
          lng: language
        })
      })
    })
    .refine(data => data.password === data.confirmPassword, {
      message: i18n?.t('sign-up.form.error-messages.password-match', {
        lng: language
      }),
      path: ['confirmPassword']
    })
    .refine(data => validatePassword.test(data.password), {
      message: i18n?.t('sign-up.form.error-messages.password-strength', {
        lng: language
      }),
      path: ['password']
    })
})

export type SignupFormProps = z.infer<typeof signupStepFormSchema>