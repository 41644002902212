// import { Card, Chip, Group, SimpleGrid, Text } from '@mantine/core'
// import { CertificateNFT } from '../MyCertificates/certificate-nft'
// import { useEffect, useState } from 'react'
// import { useCurrencyExchange } from '../../../../services/hooks/useCurrencyExchange'
// import { useCartStore } from '../../../../store'
// // import { useNavigate } from 'react-router-dom'
// import { MapNFT } from '../../../../models/map-nft'
// import { useFeedMapsByUser } from '../../../../services/hooks/useNFTs'

import { useTranslation } from 'react-i18next'

export function ConservationData() {
  const { t } = useTranslation()
  return (
  <section className="p-8 md:p-16 flex flex-col gap-4 text-zinc-50">
      <h1 className="text-3xl font-semibold">
        {t('dashboard.menu.conservation')}
      </h1>
      <p>{t('helper.coming-soon')}</p>
  </section>
)}
// export function ConservationData() {
//   const { t } = useTranslation()
//   const { currency } = useCartStore()
//   const { data: currencyValue } = useCurrencyExchange(currency)
//   const [nftData, setNFTData] = useState<MapNFT[]>([])
//   const [filter, setFilter] = useState<'all' | 'pending' | 'retrieved'>('all')
//   // const navigate = useNavigate()

//   const { data: nftDataReactQuery } = useFeedMapsByUser()

//   useEffect(() => {
//     if (nftDataReactQuery) {
//       setNFTData(nftDataReactQuery)
//     }
//   }, [nftDataReactQuery])

//   const handleChangeFilter = (value: string | string[]) => {
//     if (typeof value !== 'string') return

//     if (value === 'all' || value === 'pending' || value === 'retrieved') {
//       setFilter(value)

//       if (nftDataReactQuery && nftDataReactQuery.length > 0) {
//         switch (value) {
//           case 'all':
//             setNFTData(nftDataReactQuery)
//             break
//           case 'pending':
//             setNFTData(nftDataReactQuery?.filter(nft => !nft.isAvailable))
//             break
//           case 'retrieved':
//             setNFTData(nftDataReactQuery?.filter(nft => nft.isAvailable))
//             break
//         }
//       }
//     }
//   }
// console.log({nftData})
//   return (
//     nftDataReactQuery && nftDataReactQuery.length > 0 && nftData ?
//       <Card
//         radius="md"
//         mt="md"
//         bg="dark.5"
//         c="gray.3"
//         w="100%"
//         maw="75rem"
//         className="flex"
//       >
//         <Group justify="space-between">
//           <Text fz="xl">Dados de conservação</Text>
//           <Chip.Group defaultValue={filter} onChange={handleChangeFilter}>
//             <Group justify="center">
//               <Chip size="xs" value="retrieved" color="green.7" variant="filled">
//                 {t('dashboard.my-certificates.filter.retrieved')}
//               </Chip>
//               <Chip size="xs" value="pending" color="green.7" variant="filled">
//                 {t('dashboard.my-certificates.filter.pending')}
//               </Chip>
//               <Chip size="xs" value="all" color="green.7" variant="filled">
//                 {t('dashboard.my-certificates.filter.all')}
//               </Chip>
//             </Group>
//           </Chip.Group>
//         </Group>
//         <SimpleGrid cols={{ base: 1, sm: 2, md: 4 }} mt="md">
//           {/* {nftData.map(cartNFT => (
//             <CertificateNFT
//               key={cartNFT.id}
//               nft={cartNFT}
//               price={Number((cartNFT.price * (currencyValue || 1)).toFixed(2))}
//               currency={currency}
//               // onClick={() => navigate(cartNFT.id)}
//             />
//           ))} */}
//         </SimpleGrid>
//       </Card>
//       : <div>Loading...</div>
//   )
// }
