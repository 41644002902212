import { useEffect } from 'react'
export function ChatBotpress() {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://cdn.botpress.cloud/webchat/v1/inject.js'
    script.async = true
    script.style.zIndex = '51'
    document.body.appendChild(script)

    script.onload = () => {
      // @ts-ignore
      window.botpressWebChat.init({
        botName: 'Amazon Tree Bot',
        botId: '46574731-73ed-4e80-8ca9-bbcff43605aa',
        hostUrl: 'https://cdn.botpress.cloud/webchat/v1',
        messagingUrl: 'https://messaging.botpress.cloud',
        clientId: '46574731-73ed-4e80-8ca9-bbcff43605aa',
        className: '!z-[51]'
      })
    }
  }, [])

  return <div id="webchat"></div>
}
