import { useTranslation } from 'react-i18next'
import { FormSubmit } from '../Form'

type PrivacyPolicyProps = {
  isOpen: boolean
  onSubmit: () => void
}

export function PrivacyPolicy({ isOpen, onSubmit }: PrivacyPolicyProps) {
  const { t } = useTranslation()

  function handleSubmitPolicy() {
    onSubmit()
  }

  if (!isOpen) {
    return null
  }
  return (
    <div className="fixed w-screen h-screen bg-white bg-opacity-30 text-amz3white-150 z-[52] flex justify-center items-end">
      <div className="bg-amz3black-500 mb-24 max-w-[90%] rounded-lg p-4 flex flex-col justify-center">
        <h1 className="text-3xl font-thin">{t('privacy-policy.title')}</h1>
        <p className="mt-4 font-thin">
          {t('privacy-policy.description')}
          <a
            className="underline"
            href="https://amazon-tree-r.gitbook.io/white-paper-amz3/politica-de-privacidade"
            target="_blank"
            rel="noreferrer"
          >
            {t('privacy-policy.link')}
          </a>
        </p>

        <FormSubmit
          className="ml-auto max-w-xs h-12 mt-8"
          isSubmitting={false}
          onClick={handleSubmitPolicy}
        >
          {t('privacy-policy.submit')}
        </FormSubmit>
      </div>
    </div>
  )
}
