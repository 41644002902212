import { ReactNode } from 'react'
import { BitcoinCard, Copy, Solana, Trash } from 'iconsax-react'
import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'react-i18next'
import { CardDetailInfo } from './'
import { NftPosition } from '../../../models/nft-position'
import { CurrencyProps, useCartStore } from '../../../store/useCart'
import { GetNFTOwnerResponseProps } from '../../../services/hooks/useNFTOwner'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import {
  goToSolscanAccount,
  goToSolscanToken
} from '../../../utils/goToSolscan'
import { NFTCardDetailLoading } from './nft-card-detail-loading'

type NFTCardDetailProps = {
  id: string,
  name: string
  image: string
  artisticImage?: string | null
  nftPosition: NftPosition
  area: number
  edition: number
  currency?: CurrencyProps
  price?: number
  onAdd?: () => void
  onDelete?: () => void
  onDownload?: (nftId: string, nftName: string) => void
  children?: ReactNode
  className?: string
  nftOwner?: GetNFTOwnerResponseProps
  isLoading?: boolean
  isPopUp?: boolean
}

export function NFTCardDetail({
  id,
  name,
  image,
  artisticImage,
  nftPosition,
  area,
  edition,
  currency,
  price,
  onDelete,
  onAdd,
  onDownload,
  children,
  className,
  nftOwner,
  isLoading,
  isPopUp
}: NFTCardDetailProps) {
  const { t } = useTranslation()

  const { validNFTs } = useCartStore()
  const selectedNFT = validNFTs().find(nft => nft.id === id)

  return (
      <div
        className={twMerge(
          'bg-[#161616] w-full rounded-[20px] p-6 relative z-1',
          className
        )}
        style={{
          flex: '0 0 auto'
        }}
      >
        {isLoading ? (
          <NFTCardDetailLoading />
        ) : (
          <>
            <div className="flex items-center gap-6">
              {artisticImage?.length ? (
                <img className="w-20 h-auto block" src={artisticImage} alt="nft-logo" />
              ) :  (
                <div className="w-20 h-20 flex items-center justify-center bg-[#09FF3B] rounded-2lg">
                  <img src={image} className='"w-20 h-20' alt="nft-logo" />
                </div>
              )}
              <h1 className="font-semibold text-amz3green-50 text-left">
                <span className='text-lg'>{name?.split('#')[0]}</span>
                <span className="text-base block text-start">#{name?.split('#')[1]}</span>
                <span className="text-amz3white-50 text-sm font-semibold mt-2">
                  {t('catalog.nft-card-detail-line')} {nftPosition?.row} -{' '}
                  {t('catalog.nft-card-detail-column')} {nftPosition?.column}
                </span>
                {isPopUp && nftOwner && (
                  <>
                    <div className="flex items-center gap-1 mt-4">
                      <Link
                        to={goToSolscanToken(nftOwner.nftToken)}
                        target="_blank"
                        className="flex items-center text-amz3white-50 text-sm font-semibold"
                      >
                        <Solana className="mr-1" />
                        {nftOwner.nftToken.slice(0, 4) +
                          '..' +
                          nftOwner.nftToken.slice(-4)}
                      </Link>
                      <button
                        type="button"
                        className="ml-auto"
                        onClick={() => {
                          navigator.clipboard.writeText(nftOwner.nftToken)
                          toast.success(
                            t('catalog.nft-card-detail-copy-token-success'),
                            {
                              position: 'top-center',
                              autoClose: 3000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: false,
                              theme: 'dark'
                            }
                          )
                        }}
                      >
                        <Copy />
                      </button>
                    </div>
                    <div className="flex items-center gap-1 mt-2">
                      <Link
                        to={goToSolscanAccount(nftOwner.owner)}
                        target="_blank"
                        className="flex items-center text-amz3white-50 text-sm font-semibold"
                      >
                        <BitcoinCard className="mr-1" />
                        {nftOwner.owner.slice(0, 4) +
                          '..' +
                          nftOwner.owner.slice(-4)}
                      </Link>
                      <button
                        type="button"
                        className="ml-auto"
                        onClick={() => {
                          navigator.clipboard.writeText(nftOwner.owner)
                          toast.success(
                            t('catalog.nft-card-detail-copy-owner-success'),
                            {
                              position: 'top-center',
                              autoClose: 3000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: false,
                              theme: 'dark'
                            }
                          )
                        }}
                      >
                        <Copy />
                      </button>
                    </div>
                  </>
                )}
              </h1>
            </div>
            {children}
            <div className="flex mt-6 mb-4 w-full justify-between">
              <CardDetailInfo
                title={t('catalog.nft-card-detail-edition')}
                description={edition}
              />
              <CardDetailInfo
                title={t('catalog.nft-card-detail-area')}
                description={`${area} m²`}
              />
              {!!price && !!currency && (
                <CardDetailInfo
                  title={t('catalog.nft-card-detail-value')}
                  description={
                    !!currency
                      ? `${new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency
                        }).format(price)}`
                      : price
                  }
                />
              )}
            </div>
            {/* if isPopUp and nft is available, */}
            {/* check if is selected */}
            {/* show add/remove button */}
            {isPopUp && !nftOwner && (
              !selectedNFT ? 
                <button
                  onClick={onAdd}
                  className='button-green mt-2 w-full text-base font-semibold'>
                    {t('catalog.map-popup-button-add')}
                  </button>
              : 
                <button
                  onClick={onDelete}
                  className='button-green mt-2 w-full text-base font-semibold'>
                    {t('catalog.map-popup-button-remove')}
                  </button>
            )}
            {isPopUp && nftOwner && onDownload && (
              <button
                className='button-green-outline mt-2 w-full text-base font-semibold'
                onClick={() => onDownload(id, name)}
              >
                {t('dashboard.buttons.download-certificate')}
              </button>
            )}
            {/* if not popup and onDelete is declared, show trash icon */}
            {!isPopUp && onDelete && (
              <button
                className="absolute top-6 right-6 text-[#8A8A8A] hover:text-red-600 transition-all duration-200 ease-in leave:duration-200 leave:ease-in"
                onClick={onDelete}
              >
                <Trash size={26} />
              </button>
            )}
          </>
        )}
      </div>
  )
}
