import { LayerProps } from 'react-map-gl'
import { AREA_OPTIONS } from '../types'
import { MapNFT } from '../../../models/map-nft'

export const PROJECTS_AREAS_STATUS = {
  SELECTED: 0,
  ACTIVE: 1,
  SOLD: 2,
  RESERVED: 2,
  EXPIRED: 3,
  PRE_LAUNCH: 3,
  DISABLED: 4,
  OWNER: 5,
} as const

export const LAYER_STYLE: LayerProps = {
  id: 'data',
  type: 'fill',
  source: 'data',
  paint: {
    'fill-color': {
      property: 'status',
      stops: [
        [PROJECTS_AREAS_STATUS.SELECTED, '#f3d007'],
        [PROJECTS_AREAS_STATUS.ACTIVE, 'rgba(0, 255, 13, 0.1)'],
        [PROJECTS_AREAS_STATUS.SOLD, 'rgba(2, 26, 2, 0.9)'],
        [PROJECTS_AREAS_STATUS.RESERVED, 'rgba(2, 26, 2, 0.9)'],
        [PROJECTS_AREAS_STATUS.EXPIRED, 'rgba(2, 26, 2, 0.8)'],
        [PROJECTS_AREAS_STATUS.PRE_LAUNCH, 'rgba(2, 26, 2, 0.8)'],
        [PROJECTS_AREAS_STATUS.DISABLED, '#00ff80'],
        [PROJECTS_AREAS_STATUS.OWNER, 'rgba(3, 255, 0, 0.8)']
      ],
      type: 'exponential'
    }
  }
}

export const DEFAULT_LONG_LAT = {
  longitude: -59.49269181917168,
  latitude: -2.392991797922619
} as const

// -2.392991797922619, -59.49269181917168
// Map.setCenter(-3.066958, -59.205678,, 12)

export const MAP_INITIAL_STATE = {
  latitude: DEFAULT_LONG_LAT.latitude,
  longitude: DEFAULT_LONG_LAT.longitude,
  zoom: 3
} as const

export type MapboxMapsProps = {
  userNFTs?: MapNFT[]
  selectedOwnedNFT?: MapNFT | null
  onSelectArea?: () => void
}

export interface CheckNFTAvailabilityErrorResponse {
  messages: MessageProps[]
}

interface MessageProps {
  Item: string
  Message: MessageTypeProps
  Complement?: ComponentTypeProps
}

export type MessageTypeProps = {
  pt: string
  en: string
}

type ComponentTypeProps = {
  projectAreaId: string
  status: keyof typeof AREA_OPTIONS
  message?: string
  nftId?: string
}
