import {
  Alert,
  Card,
  Chip,
  Flex,
  Group,
  SimpleGrid,
  Text
} from '@mantine/core'
import {
  Tree as TreeIcon,
  InfoCircle as InfoCircleIcon,
  Wind as WindIcon,
} from 'iconsax-react'
import { useTranslation } from 'react-i18next'
import { CertificateNFT } from '../MyCertificates/certificate-nft'
import { useCartStore } from '../../../../store'
import { useCurrencyExchange } from '../../../../services/hooks/useCurrencyExchange'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { ROUTES_PATH } from '../../../../routes'
import { MapNFT } from '../../../../models/map-nft'
import { useFeedMapsByUser } from '../../../../services/hooks/useNFTs';

type InfoProps = {
  icon: React.FC<any>
  title: string
  value: string

}

// const infos = [
//   {
//     icon: WindIcon,
//     title: 'Vapor produzido',
//     value: '320000 m²'
//   },
//   {
//     icon: TreeIcon,
//     title: 'Area conservada',
//     value: '5000 m²'
//   }
// ]

export function Home() {
  const { t } = useTranslation()
  const { currency } = useCartStore()
  const { data: currencyValue } = useCurrencyExchange(currency)
  const navigate = useNavigate()
  const [nftData, setNFTData] = useState<MapNFT[]>([])
  const [filter, setFilter] = useState<'all' | 'pending' | 'retrieved'>('all')

  const { data: nftDataReactQuery } = useFeedMapsByUser()
  useEffect(() => {
    if (nftDataReactQuery) {
      setNFTData(nftDataReactQuery)
    }
  }, [nftDataReactQuery])

  const infos: InfoProps[] = [
    {
      icon: WindIcon,
      title: t('dashboard.my-certificates.cards.vapor'),
      value: (nftDataReactQuery?.reduce((prev, curr) => prev + curr.areaInSquareMeters * 5, 0).toFixed(2) || 0) + ' m³'
    },
    {
      icon: TreeIcon,
      title: t('dashboard.my-certificates.cards.area'),
      value: (nftDataReactQuery?.reduce((prev, curr) => prev + curr.areaInSquareMeters, 0).toFixed(2) || 0) + ' m²'
    }
  ]

  const handleChangeFilter = (value: string | string[]) => {
    if (typeof value !== 'string') return

    if (value === 'all' || value === 'pending' || value === 'retrieved') {
      setFilter(value)

      if (
        nftDataReactQuery &&
        nftDataReactQuery.length > 0) {

        switch (value) {
          case 'all':
            setNFTData(nftDataReactQuery)
            break
          case 'pending':
            setNFTData(nftDataReactQuery!.filter(nft => !nft.transferredToUserWallet))
            break
          case 'retrieved':
            setNFTData(nftDataReactQuery!.filter(nft => nft.transferredToUserWallet))
            break
        }
      }
    }
  }

  return (
    <div className="flex flex-col items-center">
      {nftData.some((nft) => !nft.transferredToUserWallet) && (
        <Alert
          variant="light"
          color="blue"
          title={t('dashboard.my-certificates.notification')}
          icon={<InfoCircleIcon size="1.25rem" />}
          w="100%"
          maw="75rem"
        >
          <Text size="md" c="gray.4">
            {t('dashboard.my-certificates.alert')}
          </Text>
        </Alert>
      )}
      
      <Card
        radius="lg"
        mt={30}
        mx={0}
        bg="transparent"
        c="gray.3"
        w="100%"
        maw="75rem"
        className="flex"
      >

        <Group justify='space-between'>
          <h1 className="text-3xl font-semibold">
            {t('dashboard.my-certificates.title')}
          </h1>

          <Chip.Group defaultValue={filter} onChange={handleChangeFilter}>
            <Group justify="left">
              <Chip
                size="xs"
                value="retrieved"
                color="green.7"
                variant="filled"
              >
                {t('dashboard.my-certificates.filter.retrieved')}
              </Chip>
              <Chip size="xs" value="pending" color="green.7" variant="filled">
                {t('dashboard.my-certificates.filter.pending')}
              </Chip>
              <Chip size="xs" value="all" color="green.7" variant="filled">
                {t('dashboard.my-certificates.filter.all')}
              </Chip>
            </Group>
          </Chip.Group>
        </Group>

        <SimpleGrid cols={{ base: 1, md: 2, xl: 4 }} mt={30} w="100%" maw="75rem">
          {infos.map(info => (
            <Card key={`card-${info.title}`} p="lg" radius="md" bg="rgba(0,0,0,.1)" c="gray.3" pos="relative">
              <info.icon
                className="absolute -left-16 top-1/2 -translate-y-1/2 text-amz3green-150 opacity-5"
                size="12rem"
              />
              <Flex className="flex z-10">
                <div>
                  <Flex align="center" gap="4px">
                    <Text fz="lg">{info.title}</Text>
                  </Flex>
                  <div>
                    <Text size="1.75rem" fw={700} mt={30} c="amz3-green.3">
                      {info.value}
                    </Text>
                  </div>
                </div>
              </Flex>
            </Card>
          ))}
        </SimpleGrid>

        <SimpleGrid cols={{ base: 1, sm: 1, md: 2, lg: 3, xl: 4 }} mt="md">
          {nftData.map(cartNFT => (
            <CertificateNFT
              key={cartNFT.id}
              nft={cartNFT}
              price={Number((cartNFT.price * (currencyValue || 1)).toFixed(2))}
              currency={currency}
              onClick={() =>
                navigate(`${ROUTES_PATH.MY_CERTIFICATES.path}/${cartNFT.id}`)
              }
            />
          ))}
        </SimpleGrid>
      </Card>
    </div>
  )
}
