import { Table } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { OrderItems } from './order-items'
import { OrdersMobile } from './orders-mobile'
import { useOrders } from '../../services'

export function Orders() {
  const { t } = useTranslation()
  const { data: ordersItems } = useOrders()

  return (
    <div className="p-8 md:p-16">
       <h1 className="text-3xl text-zinc-50 font-semibold">{t('dashboard.menu.orders')}</h1>
      <Table.ScrollContainer
        display={{ base: 'none', md: 'block' }}
        minWidth="60%"
        maw="70rem"
        mt="xl"
        c="gray.2"
      >
        <Table highlightOnHover withRowBorders={false}>
          <Table.Thead>
            <Table.Tr tt="uppercase">
              <Table.Th ta="center">Order</Table.Th>
              <Table.Th ta="center">Date</Table.Th>
              <Table.Th ta="center">Status</Table.Th>
              <Table.Th ta="center">Contract</Table.Th>
              <Table.Th ta="center">Total</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>
          {ordersItems && ordersItems.map((orderItem, idx) => (
            <OrderItems orderItem={orderItem} key={idx} />
          ))}
        </Table>
      </Table.ScrollContainer>
      <OrdersMobile />
    </div>
  )
}
