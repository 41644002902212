import React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { useTranslation } from 'react-i18next'
import { LanguageButton } from './LanguageButton'
import { LanguageProps } from '../../store/useLanguage'
import { LanguageIcon } from '@heroicons/react/20/solid'

type LanguageSelectorProps = {
  onClose: (language: LanguageProps) => void
}

export function LanguageSelector({ onClose }: LanguageSelectorProps) {
  const { t } = useTranslation()

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <button className='px-4'>
          <LanguageIcon fill='#999' width={24} />
        </button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-[#00000060] fixed inset-0 z-20" />
        <Dialog.Content className="bg-amz3black-50 fixed left-1/2 -translate-x-1/2 top-32 rounded-[23px] w-[272px] h-[227px]  z-20">
          <Dialog.Title className="text-amz3white-50 text-center mt-[33px] mb-[27px]">
            {t('language-selector.title')}
          </Dialog.Title>
          <div className="flex flex-col items-center gap-2">
            <Dialog.Close asChild>
              <LanguageButton onClick={() => onClose('pt')}>
                Português
              </LanguageButton>
            </Dialog.Close>
            <Dialog.Close asChild>
              <LanguageButton onClick={() => onClose('en')}>
                English
              </LanguageButton>
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
