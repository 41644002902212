import { ReactNode } from 'react'
import { CardDetailInfoTitle } from './card-detail-info-title'

type CardDetailInfoProps = {
  title: string
  description: string | ReactNode
}

export function CardDetailInfo({ title, description }: CardDetailInfoProps) {
  return (
    <div className="flex flex-col items-center">
      <CardDetailInfoTitle title={title} />
      <span className="text-center text-amz3green-50 text-base w-full">
        {description}
      </span>
    </div>
  )
}
