import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { ROUTES_PATH } from '../../routes'

import {
  NotificationButtonTypes,
  NotificationProps,
  NotificationStatusTypes
} from './types'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import i18n from '../../i18n';
import { api } from '../../services/api'
import { NotificationResponse } from '../../models/notification-response'

export function Notification() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [notificationJSON, setNotificationJSON] =
    useState<NotificationProps | null>(null)

  useEffect(() => {
    if (!notificationJSON && searchParams?.get('notification')) {
      setNotificationJSON(
        JSON.parse(searchParams?.get('notification') as string)
      )
    }
    searchParams?.delete('notification')
    setSearchParams(searchParams)
  }, [notificationJSON, searchParams, setSearchParams])

  const resendEmail =  useCallback(async (url :string) => {
    const {data:{messages,status}}= await api.get<NotificationResponse>(`${url}`)
     if(status === 'SUCCESS'){
       const locale = `${i18n.language}`
       const {Message} = messages[0]
       toast.success(locale==="en"?Message.en:Message.pt, {
         position: 'top-right',
         autoClose: 3000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: false,
         theme: 'dark'
       })
       navigate(ROUTES_PATH.HOME.path)
     }
   },
   [navigate])

  const buttonLabel = notificationJSON?.actionButton?.label
    ? NotificationButtonTypes[notificationJSON?.actionButton?.label].label
    : ''
  const ButtonIcon = notificationJSON?.actionButton?.label
    ? NotificationButtonTypes[notificationJSON?.actionButton?.label]?.Icon
    : null

  const statusColor = notificationJSON?.status
    ? NotificationStatusTypes[notificationJSON?.status].color
    : ''
  const StatusIcon = notificationJSON?.status
    ? NotificationStatusTypes[notificationJSON?.status].icon
    : null

  function handleButtonAction() {
    if (!notificationJSON?.actionButton?.URL) return

    if (notificationJSON?.status === 'FAIL') {
      console.log(notificationJSON?.actionButton?.URL)
      if (notificationJSON?.actionButton?.URL.includes("resend-email")) {
        resendEmail(notificationJSON?.actionButton?.URL)
       
      }
      fetch(notificationJSON?.actionButton?.URL)

    } else if (notificationJSON?.status === 'SUCCESS') {
      navigate(notificationJSON?.actionButton.URL)
    }
  }

  return (
    <div
      id={ROUTES_PATH.NOTIFICATION.id}
      className="min-h-[68.31vh] bg-amz3black-300 pt-12 pb-28 px-4 flex flex-col items-center"
    >
      {StatusIcon && (
        <StatusIcon
          size={64}
          variant="Bold"
          className={twMerge('', statusColor && `text-${statusColor}`)}
        />
      )}
      <h1
        className={twMerge(
          'mt-5 font-medium text-3xl text-center',
          statusColor && `text-${statusColor}`
        )}
      >
        {notificationJSON?.title}
      </h1>
      <p className="mt-8 text-amz3white-50 text-xl text-center">
        {notificationJSON?.message}
      </p>
      <div className="mt-24 flex flex-col gap-11 md:flex-row">
        {!!notificationJSON?.actionButton?.label && (
          <button
            onClick={handleButtonAction}
            className="bg-transparent flex items-center justify-center gap-4 h-[72px] w-[280px] rounded-2lg text-amz3green-150 font-semibold text-xl border border-amz3green-150 hover:bg-amz3green-150 hover:bg-opacity-10 transition-all duration-200 ease-in leave:duration-200 leave:ease-in"
          >
            <span>{t(buttonLabel)}</span>

            {ButtonIcon && <ButtonIcon />}
          </button>
        )}
      </div>
    </div>
  )
}
